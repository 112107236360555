


.user-main-icon {
  @apply h-[42px] w-[42px] rounded-[73%];
}
.user-1 {
  @apply flex flex-col items-center cursor-pointer;
}
.other-users {
  @apply flex gap-8 flex-row;
  /* overflow: auto; */
  width: 79%
  /* align-items: center; */
}

.other-users::-webkit-scrollbar{
  display: none;
}

.user-name {
  @apply text-[white];
}
.rest-of-users {
  @apply text-white cursor-pointer mt-[42px] m-0 p-0;
}
.chat-window {
  @apply flex flex-col fixed opacity-0 pointer-events-none transition-all duration-[0.2s] ease-[ease-in-out] inset-0;
  background: #fff;
}
.chat-window.is-open {
  @apply opacity-100 pointer-events-auto;
  /* width: 24rem; */
}
.chat-window__header {
  @apply items-center flex justify-between px-4 py-3;
  background: #1c1e2a;
}
.chat-window__title {
  @apply text-white text-lg uppercase;
}
.chat-window__body {
  @apply flex-1 overflow-auto p-4;
  background: #292f40
}
.chat-window__body > * + * {
  @apply mt-4;
}
.chat-window__footer {
  @apply items-end flex p-4;
  background: #1c1e2a;
}
.chat-window__input {
  @apply border rounded flex-1 text-[inherit] min-h-[44px] max-h-[125px] resize-none p-2.5 border-solid border-[#bbb];
  background: #fff;
  overflow: hidden;
}
.chat-window__send-btn {
  @apply rounded text-white text-[inherit] min-h-[44px] ml-4 px-4 py-0 border-0;
  font-family: inherit;
  cursor: pointer;
  background: #bdc4e0;
  color: #1c1e2a;
  font-weight: bold;
}

/* .chat-window__send-btn:hover{
  border: 1px solid black;
} */
.chat-window__send-btn:disabled {
  cursor: pointer;
  background: #bdc4e0;;
  color: #1c1e2a;
  font-weight: bold;
}
.chat-window__close-btn {
  @apply appearance-none rounded text-white cursor-pointer leading-[0] overflow-hidden p-0 border-0;
  background: #fff;
}
.chat-window__close-btn svg {
  @apply fill-[#333] h-8 w-8 transition-all duration-[0.15s] ease-[ease-in-out];
}
.chat-window__close-btn:focus > svg,
.chat-window__close-btn:hover > svg {
  @apply fill-[#555];
}
.chat-window__close-btn:focus,
.chat-window__send-btn:focus,
.chat-window__input:focus {
  @apply shadow-[0_0_0_3px_#99ceff];
  outline: 0;
}
@media (min-width: 576px) {
  .chat-window {
    @apply shadow-[0_8px_16px_rgba(0,0,0,0.2)] overflow-hidden w-[500px] rounded-lg;
  }
  .chat-window__body {
    @apply flex-[350px_0_0];
  }
  .chat-window--top-right {
    @apply left-auto right-4 top-4 bottom-auto;
  }
  .chat-window--top-left {
    @apply left-auto left-4 top-4 bottom-auto;
  }
  .chat-window--bottom-right {
    @apply left-auto right-4 top-auto bottom-4;
  }
  .chat-window--bottom-left {
    @apply left-4 right-auto top-auto bottom-4;
  }
}