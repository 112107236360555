/* .chat-message__item {
    display: flex;
  }
  .chat-message__item__timestamp {
    color: #666;
    font-size: 12px;
    margin-bottom: 3px;
  }
  .chat-message__item__text {
    background: #eee;
    border-radius: 8px;
    font-size: 15px;
    line-height: 1.2;
    padding: 8px;
    max-width: 65%;
    position: relative;
    z-index: 200;
  }
  .chat-message__item__text::after {
    content: '';
    display: block;
    width: 0px;
    height: 0px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 12px solid #eee;
    position: absolute;
    bottom: -6px;
    left: -6px;
    transform: rotate(45deg);
    z-index: 100;
  }
  .chat-message.is-same-origin .chat-message__item {
    justify-content: flex-end;
  }
  .chat-message.is-same-origin .chat-message__item__timestamp {
    text-align: right;
  }
  .chat-message.is-same-origin .chat-message__item__text {
    background: #0074d9;
    color: #fff;
  }
  .chat-message.is-same-origin .chat-message__item__text::after {
    border-top-color: #0074d9;
    transform: rotate(-45deg);
    left: auto;
    right: -6px;
  } */

  .chat-message__item {
    @apply flex;
  }
  .chat-message__item__timestamp {
    @apply text-[#666] text-xs mb-[3px];
  }
  .chat-message__item__text {
    @apply text-[15px] leading-[1.2] max-w-[65%] relative z-[200] p-2 rounded-lg after:content-[''] after:block after:w-0 after:h-0 after:absolute after:rotate-45 after:z-[100] after:border-t-[12px] after:border-t-[#eee] after:border-x-[5px] after:border-x-transparent after:border-solid after:-left-1.5 after:-bottom-1.5;
    background: #eee;
  }
  .chat-message.is-same-origin .chat-message__item {
    @apply justify-end;
  }
  .chat-message.is-same-origin .chat-message__item__timestamp {
    @apply text-right;
  }
  .chat-message.is-same-origin .chat-message__item__text {
    @apply text-white after:-rotate-45 after:border-t-[#0074d9] after:left-auto after:-right-1.5;
    background: #0074d9;
  }