body {
  @apply p-5;
  font-family: sans-serif;
}
* {
  @apply box-border;
}
.chat-btn {
  @apply items-center cursor-pointer inline-flex text-[inherit] font-[bold] uppercase border-0;
  background: transparent;
}
.chat-btn svg {
  @apply fill-[#0074d9] w-8 mr-2;
}
.main-chat-box {
  @apply fixed right-4 bottom-4;
}


/* Scroll Bar */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #292f40; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #bdc4e0; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}